.container {
  margin: 0 auto;
  max-width: 1170px;
  padding-top: 80px;
}

.foreground {
  background: white;
  padding: 12px 16px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

.grid {
  table-layout: fixed;
  width: 100%;
}

.grid tr {
  height: 56px;
}

.frame {
  border: none;
  height: 100%;
  width: 100%;
}

.loading {
  filter: blur(2px);
  user-select: none;
}