@import "vars.css";

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: var(--bg-color);
  color: var(--fg-color);
  font: var(--font-h16);
}

#root {
  height: 100%;
}
