:root {

  --main-font: "Roboto", sans-serif;

  /* colors */
  --bg-color: #F2F2F2;
  --fg-color: #2B2B2B;
  --bg-input: #303034;

  --border-input: #D1D5DB;
  --border-input-focus: #9f9fa5;
  --border-input-outline: #A99986;

  --error: #E74C3C;

  --primary: #067A46;

  --text-primary: #111827;
  --text-secondary: #6B7280;

  /* sizes */

  --input-padding: 15px;

  /* fonts */
  --font-h13: 500 13px/13px var(--main-font);
  --font-h14: 400 14px/17px var(--main-font);
  --font-h14sb: 600 14px/17px var(--main-font);
  --font-h16: 400 16px/19px var(--main-font);
  --font-h16sb: 600 16px/24px var(--main-font);
  --font-h18: 400 18px/24px var(--main-font);
  --font-h18l: 300 18px/25px var(--main-font);
  --font-h18sb: 600 18px/22px var(--main-font);
  --font-h24b: bold 24px/29px var(--main-font);
  --font-h24sb: 600 24px/29px var(--main-font);
  --font-h48sb: 600 48px/58px var(--main-font);
  --font-input: 600 18px/25px var(--main-font);

  /* transition */
  --transition-time: 0.3s;
  --transition-bg: background-color var(--transition-time) ease-out;
  --transition-border: border-color var(--transition-time) ease-out;
  --transition-opacity: opacity var(--transition-time) ease-out;
  --transition-color: color var(--transition-time) ease-out;
  --transition-width: width var(--transition-time) ease;
  --transition-right: right var(--transition-time) ease;
  --transition-bottom: bottom var(--transition-time) ease;
  --transition-visibility: visibility var(--transition-time);

}
