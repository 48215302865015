.header {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 56px;
  max-width: 300px;
  left: calc(50vw - 150px);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  /* */
  background: rgba( 88, 251, 24, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


.button {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  padding: 4px 32px;
  transition: var(--transition-bg);
}

.button:hover {
  background-color:  rgba( 88, 251, 24, 0.25 );
}

.loading {
  filter: blur(2px);
  user-select: none;
}